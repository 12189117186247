<template>
  <template v-if="show && redraw">
    <SPopover
      v-model="visible"
      trigger="hover"
      :append-to-body="appendToBody"
      :placemen="placemen"
      :fix-disappear-position="fixDisappearPosition"
      :delay-render="300"
      @before-open="onBeforeOpen"
    >
      <template #reference>
        <div
          ref="reference"
          style="display: inline-block"
        >
          <slot
            name="reference"
            :visible="visible"
          ></slot>
        </div>
      </template>
      <template #default>
        <div
          class="estimated-detail__body"
          :style="contentStyle"
        >
          <div class="estimated-detail__header estimated-detail-cell">
            <div class="estimated-detail-cell__flex">
              <div class="estimated-detail-cell__title bold">
                {{ detailTitleText }}
              </div>
              <div class="estimated-detail-cell__value bold red">
                {{ feFinallyPrice.amountWithSymbol }}
              </div>
            </div>
            <div class="estimated-detail-cell__tip">
              {{ isSuggested ? language.SHEIN_KEY_PC_30590 : language.SHEIN_KEY_PC_29828 }}
            </div>
          </div>
          <div class="estimated-detail__checklist">
            <div
              v-if="showOriginalPrice"
              class="estimated-detail-cell"
              style="line-height: 16px;"
            >
              <div class="estimated-detail-cell__flex">
                <div class="estimated-detail-cell__title">
                  <span>{{ originalPriceTitle }}:</span>
                </div>
                <div class="estimated-detail-cell__value bold">
                  {{ feOriginalPrice }}
                </div>
              </div>
              <div
                v-if="showSuggestedSalePrice"
                class="estimated-detail-cell__sub"
              >
                <span>{{ language.SHEIN_KEY_PC_31069 }}&nbsp;</span>
                <span class="estimated-detail__line-through">{{ suggestedSalePrice.amountWithSymbol }}</span>
              </div>
            </div>
            <template v-if="isSuggested">
              <!-- 合规价展示样式 -->
              <ChecklistPromotions
                ref="promotionA"
                type="promotionA"
                :language="language"
                :is-suggested="isSuggested"
                :cart-item="item"
                :integrity-promotion-info="integrityPromotionInfo"
                :flash-sale-group-list="flashSaleGroupList"
                :show-detail-price-count-down="showDetailPriceCountDown"
              />
              <div
                class="estimated-detail-cell estimated-detail-cell__flex"
                style="line-height: 16px;"
              >
                <div class="estimated-detail-cell__title">
                  <span>{{ language.SHEIN_KEY_PC_30551 }}:</span>
                </div>
                <div class="estimated-detail-cell__value bold">
                  {{ everybodyPrice.amountWithSymbol }}
                </div>
              </div>
              <ChecklistPromotions
                ref="promotionB"
                type="promotionB"
                :language="language"
                :is-suggested="isSuggested"
                :cart-item="item"
                :integrity-promotion-info="integrityPromotionInfo"
                :flash-sale-group-list="flashSaleGroupList"
                :show-detail-price-count-down="showDetailPriceCountDown"
              />
            </template>
            <template v-else>
              <!-- 正常展示样式 -->
              <ChecklistPromotions
                ref="promotion"
                :language="language"
                :is-suggested="isSuggested"
                :cart-item="item"
                :integrity-promotion-info="integrityPromotionInfo"
                :flash-sale-group-list="flashSaleGroupList"
                :show-detail-price-count-down="showDetailPriceCountDown"
              />
            </template>
            <ChecklistPrime
              :language="language"
              :cart-info="cartInfo"
              :cart-item="item"
            />
            <!-- 折扣明细需要剔除券信息 -->
            <ChecklistCoupons
              v-if="type !== 'DISCOUNT'"
              ref="ChecklistCoupons"
              :language="language"
              :cart-item="item"
              :is-suggested="isSuggested"
              :cart-coupon-infos="cartCouponInfos"
              :show-detail-price-count-down="showDetailPriceCountDown"
            />
            <ChecklistPoints
              :language="language"
              :cart-item="item"
              :is-suggested="isSuggested"
            />
          </div>
        </div>
      </template>
    </SPopover>
  </template>
  <template v-else>
    <slot name="reference"></slot>
  </template>
</template>

<script>
import { nextTick } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { mapGetters } from 'vuex'

import ChecklistPromotions from './ChecklistPromotions.vue'
import ChecklistPrime from './ChecklistPrime.vue'
import ChecklistCoupons from './ChecklistCoupons.vue'
import ChecklistPoints from './ChecklistPoints.vue'

export default {
  name: 'EstimatedDetailPopover',
  components: {
    ChecklistPromotions,
    ChecklistCoupons,
    ChecklistPrime,
    ChecklistPoints,
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: 'ESTIMATED',
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    fixDisappearPosition: Boolean,
    contentStyle: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    integrityPromotionInfo: {
      type: Object,
      required: true,
    },
    flashSaleGroupList: {
      type: Object,
      required: true,
    },
    cartInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    isSuggested: {
      type: [String, Boolean],
      default: false
    },
    showIncentiveProductListDialog: {
      type: Boolean,
      default: false
    },
    cartsId: {
      type: Array,
      default: () => []
    },
    cartCouponInfos: {
      type: Array,
      default: () => []
    },
  },
  inject: {
    data4mini: {
      default: () => (null)
    }
  },
  data() {
    return {
      redraw: true,
      visible: false,
      placemen: 'bottom',
      footerDom: null,
    }
  },
  computed: {
    ...mapGetters(['showCartPromotionsDetail', 'showDetailPriceCountDown']),
    isSuggestedSpecialDe() {
      return this.isSuggested === 'special_de'
    },
    showOriginalPrice() {
      return !this.isSuggested || this.hasPromotionA
    },
    originalPriceTitle() {
      if (this.isSuggested) {
        if (this.isSuggestedSpecialDe) return this.language.SHEIN_KEY_PC_31068
        return this.language.SHEIN_KEY_PC_30549
      }
      return this.language.SHEIN_KEY_PC_29822
    },
    feOriginalPrice() {
      const { item } = this
      if (!item?.product) return ''
      if (item.product?.insurable == 1 && item.product.sale_price_before_insured) return item.product.sale_price_before_insured.amountWithSymbol
      return item.product.feOriginalPrice.amountWithSymbol
    },
    showSuggestedSalePrice() {
      return this.isSuggestedSpecialDe &&
        !!+this.suggestedSalePrice?.amount
    },
    // 30天最低价
    suggestedSalePrice() {
      return this.item?.product?.suggested_sale_price || {}
    },
    feFinallyPrice() {
      return this.item?.product?.feFinallyPrice || {}
    },
    everybodyPrice() {
      return this.item?.every_body_price || {}
    },
    showSingleOtherDiscount() {
      return this.item?.single_other_discount?.amount > 0
    },
    hasPromotionA() {
      if (!this.isSuggested) return false
      return this.item?.promotion_discount?.some(s => s.every_body_price_type === 1) ||
        this.showSingleOtherDiscount
    },
    detailTitleText() {
      if (this.type === 'DISCOUNT') {
        return this.language.SHEIN_KEY_PC_31373
      }
      return this.isSuggested ? this.language.SHEIN_KEY_PC_30553 : this.language.SHEIN_KEY_PC_29827
    },
  },
  watch: {
    visible(value) {
      if (!value) return
      this.$nextTick(() => {
        this.$refs.promotion?.reset?.()
        this.$refs.promotionA?.reset?.()
        this.$refs.promotionB?.reset?.()
        this.$refs.ChecklistCoupons?.reset?.()

        let id = []
        if (this.showDetailPriceCountDown) {
          let fastestEndPromotion = {}
          if (this.isSuggested) {
            fastestEndPromotion = this.$refs.promotionA?.fastestEndPromotion
          } else {
            fastestEndPromotion = this.$refs.promotion?.fastestEndPromotion
          }
          const promotionEndIn24h = fastestEndPromotion?.endIn24h
          const promotionId = fastestEndPromotion?.id

          const fastestEndCoupon = this.$refs.ChecklistCoupons?.fastestEndCoupon ?? {}
          const couponEndIn24h = fastestEndCoupon?.endIn24h
          const couponCode = fastestEndCoupon?.couponCode

          if (promotionEndIn24h) { id.push(promotionId) }
          if (couponEndIn24h) { id.push(couponCode) }
        }

        if (this.type === 'DISCOUNT') {
          daEventCenter.triggerNotice({
            daId: '1-7-3-64',
            extraData: {
              sku_code: this.item.product.sku_code,
              cart_id: this.item.id,
              goods_id: this.item.product.goods_id,
              result_order: this.cartsId?.indexOf(this.item.id) + 1,
              countdown_promotion_id: id?.join(',') || '-',
            },
          })
        } else {
          daEventCenter.triggerNotice({
            daId: '1-7-3-52',
            extraData: {
              sku_code: this.item.id,
              is_checked: this.item.is_checked,
              scene: this.showIncentiveProductListDialog ? 'benefit_pop' : 'cart',
              countdown_promotion_id: id?.join(',') || '-'
            },
          })
        }
      })
    }
  },
  methods: {
    /**
     * 在 mini 车时，判断底部空间是否足够展示优惠明细 popover，并设置 placemen
     */
    setPlacemen() {
      if (!this.data4mini) return
      if (this.data4mini.value.showDrawer) {
        this.footerDom = document.querySelector('.j-side-mini-cart .j-side-cart-bottom')
      }
      if (this.data4mini.value.showHeadBag) {
        this.footerDom = document.querySelector('.j-side-mini-cart__old .j-side-cart-bottom')
      }
      if (!this.footerDom) return
      const clientRect = this.$refs.reference.getBoundingClientRect()
      const  footerClientRect = this.footerDom.getBoundingClientRect()
      const tagPos = clientRect.height + clientRect.top
      const footerPos = footerClientRect.top
      let placemen = ''
      if (footerPos - tagPos < 240) {
        placemen = 'top'
      } else {
        placemen = 'bottom'
      }
      if (this.placemen !== placemen) {
        this.placemen = placemen
        // 解决更改 placemen 存在两个相同 popover 的问题
        this.redraw = false
        nextTick(() => {
          this.redraw = true
        })
      }
    },
    onBeforeOpen() {
      this.setPlacemen()
    }
  },
}
</script>

<style lang="less">
.estimated-detail-cell {
  margin: 8px 0;
  font-size: 14px;
  color: #000;
  line-height: normal;
  .bold {
    font-weight: 700;
  }
  .red {
    color: #FA6338;
  }
  &.pointer,
  .pointer {
    cursor: pointer;
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    line-height: 14px;
  }
  &__title {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
  &__value {
    flex-shrink: 0;
    position: relative;
    max-width: 65%;
    text-align: right;
    word-break: break-all;
  }
  &__tip {
    margin-top: 4px;
    font-size: 10px;
    color: rgba(#000, .3);
  }
  &__sub {
    // margin-top: 4px;
    font-size: 10px;
    color: @sui_color_gray_light1;
  }
}
</style>

<style lang="less" scoped>
.estimated-detail {
  &__body {
    margin-right: -12px;
    padding-right: 12px;
    min-width: 248px;
    height: 188px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-gutter: stable;
    /* 滚动条整体样式 */
    &::-webkit-scrollbar {
      margin-left: 4px;
      width: 4px; /* 滚动条宽度 */
    }
    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background-color: #fff; /* 滚动条轨道背景色 */
    }
    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background-color: #ababab; /* 滚动条滑块背景色 */
      border-radius: 4px; /* 滚动条滑块圆角 */
    }
  }
  &__header {
    &.estimated-detail-cell {
      margin-top: 0;
      font-size: 16px;
      .estimated-detail-cell__tip {
        line-height: 14px;
      }
    }
  }
  &__checklist {
    .estimated-detail-cell {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__line-through {
    text-decoration: line-through;
  }
}
</style>
