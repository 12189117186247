<template>
  <div
    v-if="show"
    class="estimated-detail-cell"
  >
    <div class="estimated-detail-cell__flex">
      <div class="estimated-detail-cell__title">
        <span>{{ language.SHEIN_KEY_PC_29826 }}:</span>
      </div>
      <div class="estimated-detail-cell__value  bold red">
        -{{ singlePointDiscount.amountWithSymbol }}
      </div>
    </div>
    <div class="estimated-detail-cell__tip">
      {{ isSuggested ? language.SHEIN_KEY_PC_30555 : language.SHEIN_KEY_PC_27856 }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    language: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
      required: true,
    },
    isSuggested: {
      type: [String, Boolean],
      default: false
    }
  },
  computed: {
    show() {
      return !!+this.singlePointDiscount.amount
    },
    singlePointDiscount() {
      return this.cartItem?.single_point_discount || {}
    },
  },
}
</script>
