<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import { Icon } from '@shein-aidc/icon-vue3'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_new/utils/index'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  item: {
    type: Object,
    default: () => {}
  },
  // 0: 不限购 -1: 同类商品的其他sku已经满足或超过限购数量（该商品行只能选1）
  limitNum: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  // 使用场景 购物车页：cart   mini购物车：mini
  page: {
    type: String,
    default: 'cart' 
  },
  // 滚动父元素 
  scrollParent: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['showInputNumberTips', 'handleQuantity'])

// 购物车vuex相关，mini购物车没有
let store = null
if (props.page === 'cart') {
  store = useStore()
}
const cartList = computed(() => store?.state?.cartList)
// const soldoutIds = computed(() => store?.state?.soldoutIds)
const updateField = (payload) => store?.commit('updateField', payload)
const asyncUpdateCartInfo = (payload) => store?.dispatch('asyncUpdateCartInfo', payload)
const handleFlashSaleOverScene = (payload) => store?.dispatch('handleFlashSaleOverScene', payload)
const fetchModifyCartCheckStatus = (payload) => store?.dispatch('fetchModifyCartCheckStatus', payload)

const quantity = ref('')
watch(() => props.item.quantity, (val) => {
  quantity.value = +val
}, {
  immediate: true
})
// 输入框限制最大值
const inputLimitNum = computed(() => {
  if (props.limitNum === 0) {
    return Math.min(props.item.realTimeInventory, 99)
  } else if (props.limitNum === -1) {
    return Math.min(props.item.realTimeInventory, 1)
  } else {
    return Math.min(props.item.realTimeInventory, props.limitNum, 99)
  }
})
// 是否库存不足导致失效
const isSoldoutByInventory = computed(() => {
  return props.item.status == 1 && props.item.realTimeInventory && props.item.quantity > props.item.realTimeInventory
})
// 是否在mini购物车使用
const isMiniCart = computed(() => {
  return props.page === 'mini'
})

const getOption = (value, canSelectNum) => {
  return {
    label: value > 10 ? '10+' : value + '',
    value: value > 10 ? '' : value,
    disabled: value > canSelectNum
  }
}
const options = computed(() => {
  // 可勾选的数量
  let canSelectNum = 10
  // 显示的勾选数量
  let showSelectNum = 10
  // 选项
  let options = []
  if (props.limitNum === 0) {
    showSelectNum = canSelectNum = props.item.realTimeInventory
  } else if (props.limitNum === -1) {
    showSelectNum = canSelectNum = Math.min(props.item.realTimeInventory, 1)
  } else {
    showSelectNum = canSelectNum = Math.min(props.item.realTimeInventory, props.limitNum)
  }

  if (props.item.quantity > canSelectNum) {
    showSelectNum = props.item.quantity
  }
  if (showSelectNum > 10) {
    showSelectNum = 11
  }

  for (let i = 1; i <= showSelectNum; i++) {
    let option = getOption(i, canSelectNum)
    options.push(option)
  }
  return options
})

const selectHeight = computed(() => {
  return 24 * options.value.length
})
const getPlacement = (qtyEl) => {
  const { top, bottom } = qtyEl.getBoundingClientRect()
  if (props.scrollParent) {
    const scrollParentEl = document.querySelector(props.scrollParent)
    if (scrollParentEl) {
      const { top: mTop, bottom: mBottom } = scrollParentEl.getBoundingClientRect()
      const spaceAbove = top - mTop - selectHeight.value
      const spaceBelow = mBottom - bottom - selectHeight.value
      if (spaceAbove < 12 && spaceBelow < 12) {
        return 'bottom'
      }
      return spaceAbove > spaceBelow ? 'top' : 'bottom'
    }
  }
  const { innerHeight } = window
  const spaceBelow = innerHeight - bottom - selectHeight.value
  if (spaceBelow < 12) {
    return 'top'
  } else {
    return 'bottom'
  }
}

const transitionNames = {
  top: 'sui-animation__selectmenu_top',
  bottom: 'sui-animation__selectmenu'
}

const isShowSelect = ref(false)
const isInputFocus = ref(false)
const qtyRef = ref(null)
const inputRef = ref(null)
const selectRef = ref(null)
// 气泡弹出的位置
const placement = ref('bottom')
const transitionName = ref('sui-animation__selectmenu')
const isShowInputNumberTips = ref(false)
const clickSuffix = async () => {
  if (props.disabled) return
  if (!isShowSelect.value) {
    const p = getPlacement(qtyRef.value)
    placement.value = p
    transitionName.value = transitionNames[p]
    isShowSelect.value = true
    await nextTick()
    selectRef.value.focus()
  } else {
    isShowSelect.value = false
  }
}
const clickInputWrapper = (e) => {
  if (props.disabled) return
  if (e.target.tagName === 'LABEL') {
    const range = quantity.value > 9 ? 2 : 1
    inputRef.value.setSelectionRange(range, range)
    inputRef.value.focus()
  }
  daEventCenter.triggerNotice({
    daId: '1-7-3-46',
    extraData: {
      skc: props.item.product?.goods_sn || '',
      sku: props.item.product?.sku_code || '',
    }
  })
}
const clickSelectItem = (item) => {
  const { value, disabled = false } = item
  if (disabled) return
  if (value === '') {
    isShowSelect.value = false
    quantity.value = ''
    inputRef.value.focus()
    isShowInputNumberTips.value = !isShowInputNumberTips.value
    emit('showInputNumberTips', isShowInputNumberTips.value)
    daEventCenter.triggerNotice({
      daId: '1-7-3-45',
      extraData: {
        skc: props.item.product?.goods_sn || '',
        sku: props.item.product?.sku_code || '',
        result: '-'
      }
    })
    return
  }
  if (value === quantity.value) {
    isShowSelect.value = false
    return
  }
  quantity.value = value
  isShowSelect.value = false
  handleQuantity(props.item, 2)
}
const handleInputBlur = () => {
  isInputFocus.value = false
  isShowInputNumberTips.value = false
  emit('showInputNumberTips', isShowInputNumberTips.value)

  if (quantity.value === '') {
    quantity.value = props.item.quantity
    return
  }
  quantity.value = +quantity.value
  if (quantity.value === +props.item.quantity) {
    return
  }
  handleQuantity(props.item, 1)
}
const handleSelectBlur = async () => {
  if (isShowSelect.value) {
    isShowSelect.value = false
  }
}
const handleInput = (e) => {
  quantity.value = e.target.value.replace(/\D/g, '')

  if (quantity.value === '0') {
    quantity.value = ''
  }
  if (quantity.value > inputLimitNum.value) {
    quantity.value = inputLimitNum.value
  }
  isShowInputNumberTips.value = false
  emit('showInputNumberTips', isShowInputNumberTips.value)
}

const handleInputEnterKeyup = () => {
  if (quantity.value === '' || +quantity.value === +props.item.quantity) {
    return
  }
  inputRef.value.blur()
}

const isCanEdit = computed(() => {
  return props.item.product.product_promotion_info && props.item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type)) ? false : true
})
const modifyCartCheckStatus = (item) => {
  let reqParam = {
    operation_type: 1,
    cart_id_list: []
  }
  if(item.is_checked == 0) {
    reqParam.cart_id_list.push(item.id)
    reqParam.editCartId = item.id
  }
  reqParam.cart_id_list = reqParam.cart_id_list.concat(item.appendIds || [])
  reqParam.cart_id_list.length && fetchModifyCartCheckStatus(reqParam)
}
/**
 * 变更数量
 * @param {*} item 商品行信息
 * @param {*} action 触发数量变更的方式 1: 输入框 2: 下拉框
 */
const handleQuantity = (item, action) => {
  if (props.page !== 'cart') {
    emit('handleQuantity', { item, type: 3, quantity: quantity.value, action })
    return
  }

  if (props.disabled || !isCanEdit.value || item.isNewcomerItem) return

  const old_num = parseInt(item.quantity)
  updateField({ key: 'bigLoading', value: true })
  schttp({
    url: '/api/cart/update',
    method: 'POST',
    data: {
      id: item.id,
      quantity: quantity.value,
      trace_id: gbExposeTraceid('getProduct', { goods_id: (item.product && item.product.goods_id) || '' }) || item.trace_id || '',
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then( async (res) => {
    updateField({ key: 'bigLoading', value: false })
    daEventCenter.triggerNotice({
      daId: action === 1 ? '1-7-3-47' : '1-7-3-45',
      extraData: {
        skc: item.product?.goods_sn || '',
        sku: item.product?.sku_code || '',
        result: res.code == 0 ? 1 : 0
      }
    })
    
    if (res.code == 0) {
      let originCarts = JSON.parse(JSON.stringify(cartList.value))
      await asyncUpdateCartInfo({ cartInfo: res.info.info })
      handleFlashSaleOverScene({ editCartId: item.id, carts: res.info.info?.carts, originCarts: originCarts })
    } else{
      quantity.value = old_num
      let errorMessage = handleUpdateAndUpdateAttrErr(res, props.language, item)
      errorMessage.msg && SMessage({
        message: errorMessage.msg,
        type: errorMessage.times ? 'error' : 'warning',
        offset: 180,
        duration: errorMessage.times || 3000
      })
    }
    // 接入 apollo 控制是否调用 check 接口
    if (res.CART_AUTO_CHECKOUT !== 'ON') modifyCartCheckStatus(item)
  })

  //购物车缺货商品更改数量不提交ga
  if (item.realTimeInventory > 0) {
    GB_analysis_obj.handleQty({
      item: item,
      old_num: old_num
    })
  }
}
</script>

<template>
  <div class="qty">
    <div
      ref="qtyRef"
      v-expose="{ id: '1-7-3-44', data: { skc: item.product?.goods_sn || '', sku: item.product?.sku_code || '' } }"
      class="qty__content"
      :class="{
        'qty__content_mini': isMiniCart,
        'qty__content_focus': isInputFocus,
        'qty__content_disabled': disabled,
      }"
    >
      <div
        class="qty__input-wrapper"
        @click="clickInputWrapper"
      >
        <!-- 使用for让input聚焦，光标位置没有在最后，所以用的别方案 -->
        <label
          class="qty__label"
          :class="{
            'qty__label_mini': isMiniCart,
            'qty__label_disabled': disabled
          }"
        >Qty:</label>
        <div class="qty__input-box">
          <input
            ref="inputRef"
            v-model="quantity"
            type="text"
            class="qty__input"
            :class="{
              'qty__input_inventory-error': isSoldoutByInventory && quantity > inputLimitNum && !disabled,
              'qty__input_disabled': disabled,
              'qty__input_max': quantity > 9 || (quantity === '' && item.quantity > 9),
            }"
            autocomplete="off"
            :disabled="disabled"
            :placeholder="item.quantity"
            @focus="isInputFocus = true"
            @blur.stop="handleInputBlur"
            @input="handleInput"
            @keyup.enter="handleInputEnterKeyup"
          />
        </div>
      </div>
      <div
        class="qty__suffix"
        :class="{
          'qty__suffix_mini': isMiniCart,
          'qty__suffix_disabled': disabled
        }"
        @mousedown.prevent
        @click="clickSuffix"
      >
        <Icon
          name="sui_icon_more_down_14px"
          size="14px"
          class="qty__suffix-icon"
          :class="{
            'qty__suffix-icon_actived': isShowSelect,
          }"
        />
      </div>
    </div>
    <Transition :name="transitionName">
      <div
        v-show="isShowSelect"
        ref="selectRef"
        tabindex="-1"
        class="qty__select"
        :class="[
          `qty__select_${placement}`,
          {
            'qty__select_mini': isMiniCart,
          }
        ]"
        @blur.stop="handleSelectBlur"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="qty__select-item"
          :class="{
            'qty__select-item_selected': option.value === item.quantity || (option.value === '' && item.quantity > 10),
            'qty__select-item_disabled': option.disabled
          }"
          @click="clickSelectItem(option)"
        >
          <span
            class="label"
          >{{ option.label }}</span>
          <Icon
            v-if="(option.value === item.quantity || (option.value === '' && item.quantity > 10)) && option.disabled"
            name="sui_icon_selected_16px"
            size="14px"
            color="#CCC"
          />
          <Icon
            v-if="(option.value === item.quantity || (option.value === '' && item.quantity > 10)) && !option.disabled"
            name="sui_icon_selected_16px"
            size="14px"
            color="#000"
          />
        </div>
      </div>
    </Transition>
    <div
      v-show="isShowInputNumberTips"
      class="qty__tips"
      :class="{
        'qty__tips_mini': isMiniCart,
      }"
    >
      {{ language.SHEIN_KEY_PC_28787 }}
    </div>
  </div>
</template>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.qty {
  position: relative;
  .qty__content {
    box-sizing: border-box;
    display: flex;
    width: 87px;
    height: 24px;
    border: 0.5px solid #ccc;
    border-radius: 12px;
    font-family: Arial, system-ui;
    background-color: #fff;
    &_mini {
      width: 75px;
      height: 20px;
      border-radius: 10px;
    }
    &_focus {
      border-color: #000;
    }
    &_disabled {
      border-color: #E5E5E5;
      background-color: #F6F6F6;
      color: #CCC;
      cursor: not-allowed;
    }
    &:not(.qty__content_disabled) {
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover {
        border-color: #000;
      }
    }
  }
  .qty__input-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .qty__label {
    padding-left: 12px;
    margin-right: 2px;
    line-height: 14px;
    font-size: 12px;
    &_mini {
      padding-left: 6px;
    }
  }
  .qty__label_disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  .qty__input-box {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .qty__input {
    width: 10px;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    color: #222;
    &_inventory-error {
      color: @sui_color_unusual;
    }
    &_disabled {
      cursor: not-allowed;
      pointer-events: none;
      color: #CCC;
    }
    &_max {
      width: 16px;
    }
    &::placeholder {
      color: #CCC;
      opacity: 1; /* Firefox 需要设置不透明度 */
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: #CCC;
      opacity: 1;
    }

    /* Internet Explorer 10-11 */
    :-ms-input-placeholder {
      color: #CCC;
    }

    /* Edge */
    ::-ms-input-placeholder {
      color: #CCC;
    }

    /* Safari and other WebKit-based browsers */
    ::-webkit-input-placeholder {
      color: #CCC;
    }
  }
  .qty__suffix {
    display: flex;
    align-items: center;
    padding-right: 12px;
    height: 100%;
    cursor: pointer;
    &_mini {
      padding-right: 6px;
    }
    &_disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .qty__suffix-icon {
    transition: transform 0.2s ease-in-out;
    &_actived {
      transform: rotate(180deg);
    }
  }

  .qty__select {
    position: absolute;
    right: 0;
    width: 64px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
    background-color: #fff;
    z-index: 101;
    will-change: transform;
    &::before {
      content: '';
      position: absolute;
      right: 6px;
      width: 0;
      height: 0;
      border-right: 14px solid transparent;
      border-left: 14px solid transparent;
    }
    &_mini {
      z-index: @zindex-out;
      &::before {
        right: 0;
      }
    }
    &_bottom {
      top: calc(100% + 10px);
      &::before {
        top: -8px;
        border-top: none;
        border-bottom: 14px solid #fff;
      }
    }
    &_top {
      bottom: calc(100% + 10px);
      &::before {
        bottom: -8px;
        border-top: 14px solid #fff;
        border-bottom: none;
      }
    }
  }
  .qty__select-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    font-family: Arial, system-ui;
    color: #222;
    cursor: pointer;
    z-index: @zindex-hack;
    &:hover {
      background-color: #F2F2F2;
    }
  }
  .qty__select-item_selected {
    .label {
      font-weight: 700;
    }
  }
  .qty__select-item_disabled {
    cursor: not-allowed;
    background-color: #F6F6F6;
    &:hover {
      background-color: #F6F6F6;
    }
    .label {
      color: #CCC;
    }
  }


  .qty__tips {
    position: absolute;
    left: 50%;
    bottom: -14px;
    transform: translateX(-50%);
    max-width: 330px;
    font-size: 10px;
    color: #999;
    line-height: normal;
    .text-overflow();
    &_mini {
      max-width: 100px;
    }
  }
}


.sui-animation__selectmenu_top-enter,
.sui-animation__selectmenu_top-enter-active {
  animation: sui-selectmenu-in-top 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sui-animation__selectmenu_top-leave,
.sui-animation__selectmenu_top-leave-active {
  animation: sui-selectmenu-out-top 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes sui-selectmenu-in-top {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sui-selectmenu-out-top {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

</style>
