<template>
  <span :style="resizeFontStyle">
    <slot>{{ content }}</slot>
  </span>
</template>

<script>
/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText></ResizeFont>
 */
export default {
  name: 'ResizeFontText',

  inject: ['registerComponent'],
  emits: ['resizeDone'],

  props: {
    initFontSize: {
      type: Number,
      default: 18
    },
    resizeFontMin: {
      type: Number,
      default: 12
    },
    resizeFontStep: {
      type: Number,
      default: 2
    },
    content: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      resizeFont: this.initFontSize,
      isResizeDone: false,
    }
  },

  computed: {
    resizeFontStyle() {
      return {
        fontSize: `${this.resizeFont}px`
      }
    },
  },

  mounted() {
    this.registerComponent(this)
  },

  methods: {
    /**
     * 缩放字体
     */
    resizeFontSize() {
      if (this.isResizeDone) return
      if (this.resizeFont <= this.resizeFontMin) {
        this.isResizeDone = true
        this.$emit('resizeDone')
        return
      }
      this.resizeFont = Math.max(this.resizeFont - this.resizeFontStep, this.resizeFontMin)
    }
  }
}
</script>
