<template>
  <template v-if="primeDiscount">
    <div class="estimated-detail-cell estimated-detail__prime">
      <div class="estimated-detail-cell__flex">
        <div class="estimated-detail-cell__title estimated-detail__prime-title">
          <span>{{ language.SHEIN_KEY_PC_22977 }}:</span>
        </div>
        <div class="estimated-detail-cell__value estimated-detail__prime-value">
          <Icon
            name="sui_icon_club_logo_fill_14px"
            size="14px"
            :color="primeColor"
          />
          <span
            :style="{
              color: primeColor,
            }"
            class="estimated-detail__prime-amount bold"
          >
            -{{ singleDiscountValue.amountWithSymbol }}
          </span>
        </div>
      </div>
      <div
        v-if="primeVipOverQuotaTip"
        class="estimated-detail-cell__tip"
      >
        {{ primeVipOverQuotaTip }}
      </div>
    </div>
  </template>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'

export default {
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      required: true,
    },
    cartInfo: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    primeDiscount() {
      return this.cartItem.promotion_discount?.find(item => item.promotion_type == '29')
    },
    singleDiscountValue() {
      return this.primeDiscount?.single_discount_value
    },
    primeVipOverQuotaTip() {
      const promotionGrantAmountInfo = this.cartInfo && this.cartInfo.promotion_grant_amount_info || {}
      const { cycle_used_grant_amount, cycle_grant_amount } = promotionGrantAmountInfo || {}
      return promotionGrantAmountInfo?.is_has_quota == 1 && cycle_used_grant_amount?.amountWithSymbol && cycle_grant_amount?.amountWithSymbol ? template(cycle_used_grant_amount.amountWithSymbol, cycle_grant_amount.amountWithSymbol, this.language.SHEIN_KEY_PC_23940) : ''
    },
    primeColor() {
      return this.cartItem.isDiscountLogoA ? '#873C00' : '#C96E3F'
    },
  },
  methods: {
    template,
  },
}
</script>

<style lang="less">
.estimated-detail {
  &__prime-title {
    font-size: 13px;
  }
  &__prime-value {
    display: flex;
    align-items: center;
  }
  &__prime-amount {
    margin-left: 4px;
    font-size: 13px;
    line-height: 14px;
  }
}
</style>
