<template>
  <template v-if="render">
    <EstimatedDetailPopover
      :show="estimatedDetailSwitch"
      :append-to-body="appendToBodyForPopover"
      :fix-disappear-position="fixDisappearPosition"
      :content-style="contentStyleForPopover"
      :language="language"
      :item="item"
      :checked-prime="true"
      :integrity-promotion-info="integrityPromotionInfo"
      :flash-sale-group-list="flashSaleGroupList"
      :cart-info="cartInfo"
      :cart-coupon-infos="cartCouponInfos"
      :is-suggested="isSuggested"
      :show-incentive-product-list-dialog="showIncentiveProductListDialog"
    >
      <template #reference="{ visible }">
        <div
          ref="target"
          v-expose="{
            id: '1-7-3-51',
            data: {
              sku_code: item.id,
              is_checked: item.is_checked,
              is_change: isChange,
              scene: showIncentiveProductListDialog ? 'benefit_pop' : 'cart',
            }
          }"
          :class="[
            'estimated-tag',
            estimatedDetailSwitch && 'pointer',
            primeClassName,
          ]"
        >
          <span class="estimated">{{ estimatedText }}</span>
          <span
            v-if="showDiscount"
            class="discount"
          >
            {{ discountText }}
          </span>
          <template v-if="isShowCartrowEstimatedCountdown && canShowCountdown">
            <i class="line">|</i>
            <ClientOnly>
              <count-down-time
                class="time-down"
                font-size="10px"
                not-hide
                not-reload
                :time-stamp="+timeStamp"
                :show-type="'inline-promotion'"
              />
            </ClientOnly>
          </template>
          <template v-if="estimatedDetailSwitch">
            <sui_icon_more_down_12px_1
              size="12px"
              :color="isPremiumMember ? primeColor : '#FA6338'"
              :class="[
                'icon__more-down',
                visible && 'visible',
              ]"
            />
          </template>
        </div>
      </template>
    </EstimatedDetailPopover>
  </template>
</template>

<script>
import { sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'
import EstimatedDetailPopover from '../EstimatedDetailPopover/index.vue'
import { getTimeStamp, canShowCountdown } from './utils'
import { template } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
export default {
  name: 'EstimatedTag',

  components: {
    sui_icon_more_down_12px_1,
    EstimatedDetailPopover,
    ClientOnly
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    cartAbtInfo: {
      type: Object,
      default: () => {}
    },
    appendToBodyForPopover: {
      type: Boolean,
      default: true,
    },
    fixDisappearPosition: Boolean,
    contentStyleForPopover: {
      type: Object,
      default: () => {}
    },
    isBatchActivePage: {
      type: Boolean,
      default: false,
    },
    isShowCartrowEstimatedCountdown: {
      type: Boolean,
      default: false
    },
    cartCouponInfos: {
      type: Array,
      default: () => []
    },
    integrityPromotionInfo: {
      type: Object,
      default: () => {},
      required: true,
    },
    flashSaleGroupList: {
      type: Object,
      required: true,
    },
    cartInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    abtInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    isSuggested: {
      type: [String, Boolean],
      default: false
    },
    showIncentiveProductListDialog: {
      type: Boolean,
      default: false
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
    discountText: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      render: true,
      isChange: '-',
    }
  },

  computed: {
    estimatedDetailSwitch() {
      return !this.isBatchActivePage
    },
    canShowCountdown() {
      return canShowCountdown(this.item, this.cartCouponInfos, this.integrityPromotionInfo)
    },
    timeStamp() {
      return getTimeStamp(this.item, this.cartCouponInfos, this.integrityPromotionInfo)
    },
    primeColor() {
      return this.abtInfo.primeIconV2 ? '#873C00' : '#C96E3F'
    },
    isPremiumMember(){
      return this.item.promotionTypeIds.includes(29) ? true : false
    },
    primeClassName() {
      if(this.isPremiumMember) {
        if(this.abtInfo.primeIconV2) return 'prime-style-a'
        else return 'prime-style-b'
      }
      return ''
    },
    estimatedText(){
      return this.isSuggested ? template(this.item.product.feFinallyPrice.amountWithSymbol, '{0} ' + this.language.SHEIN_KEY_PC_30553) : this.language.SHEIN_KEY_PC_29465
    },
    isCheckedFeFinallyPrice() {
      return {
        is_checked: this.item.is_checked,
        feFinallyPrice: this.item.product.feFinallyPrice,
      }
    },
  },

  watch: {
    isCheckedFeFinallyPrice(v, o) {
      // 如果勾选状态没变，不需要判断金额变化
      if (v.is_checked === o.is_checked) return
      // 如果上一次未显示到手价标签，则无需手动上报
      if (!o.feFinallyPrice) return

      this.isChange = this.getIsChangeByUnitPrice(o.feFinallyPrice?.amount, v.feFinallyPrice?.amount)
      this.onExpose()
    },
  },

  methods: {
    template,
    getIsChangeByUnitPrice(beforePrice, price) {
      if (!beforePrice || !price) return '-'
      if (+beforePrice === +price) return 'remain'
      if (+beforePrice - +price > 0) return 'smaller'
      return 'increase'
    },
    onExpose() {
      this.$nextTick(() => {
        if (this.getInViewport()) {
          // 在视图中，手动曝光一次
          daEventCenter.triggerNotice({
            daId: '1-7-3-51',
            bindData: [
              {
                sku_code: this.item.id,
                is_checked: this.item.is_checked,
                is_change: this.isChange,
                scene: this.showIncentiveProductListDialog ? 'benefit_pop' : 'cart',
              },
            ],
          })
        } else {
          // 可能已经曝光过，所以需要重新渲染再次订阅曝光事件
          this.onRender()
        }
      })
    },
    getInViewport() {
      const targetRect = this.$refs.target.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      return targetRect.top < viewportHeight && targetRect.bottom > 0
    },
    onRender() {
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
  }
}
</script>
  
<style lang="less" scoped>
.estimated-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 18px;
  line-height: 18px;
  padding: 2px 4px;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%), linear-gradient(90deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.30) 100.8%);
  &.pointer {
    cursor: pointer;
  }
  .icon__more-down {
    &.visible {
      transform: rotateX(180deg);
    }
  }
  .estimated,
  .discount {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #FA6338;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    direction: ltr /*rtl:ignore*/;
  }
  .discount {
    margin-left: 2px;
    font-weight: 500;
  }
  .line {
    display: inline-block;
    width: 4px;
    color: #FDB9A6;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin: 0 4px;
  }
  /* stylelint-disable-next-line */
  :deep(.c-count-down-time .time-content span) {
    font-weight: normal !important; /* stylelint-disable-line declaration-no-important */
    color: #FA6338;
  }
  .time-down {
    margin-top: 0!important; /* stylelint-disable-line declaration-no-important */
    font-size: 10px;
    height: 18px;
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.time-content) {
      display: flex;
      align-items: center;
      font-weight: normal!important; /* stylelint-disable-line declaration-no-important */
      color: #FA6338;
      height: 18px;
      line-height: 12px;
    }
  }
}
.estimated-tag.prime-style-a {
  .estimated,
  .discount {
    color: #873C00;
  }
  .line {
    color: #873C00;
    opacity: 0.4;
  }
  /* stylelint-disable-next-line */
  :deep(.c-count-down-time .time-content span) {
    color: #873C00;
  }
  .time-down {
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.time-content) {
      color: #873C00;
    }
  }
}
.estimated-tag.prime-style-b {
  .estimated,
  .discount {
    color: #C96E3F;
  }
  .line {
    color: #C96E3F;
    opacity: 0.4;
  }
  /* stylelint-disable-next-line */
  :deep(.c-count-down-time .time-content span) {
    color: #C96E3F;
  }
  .time-down {
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.time-content) {
      color: #C96E3F;
    }
  }
}
</style>
