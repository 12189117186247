<template>
  <div
    class="preferred-seller-icon"
    :style="{width: width+'px', height: height+'px'}"
  >
    <img :src="`${PUBLIC_CDN}/she_dist/images/cart/sui_icon_preferred_seller-9f0612ec30.png`" />
  </div>
</template>

<script>
const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'PreferredSellerStoreIcon',
  props: {
    width: {
      type: [Number, String],
      default: '20'
    },
    height: {
      type: [Number, String],
      default: '20'
    },
    publicCdn: {
      type: String,
      default: ''
    }
  },
  computed: {
    PUBLIC_CDN() {
      return this.publicCdn || PUBLIC_CDN || ''
    }
  },
}
</script>

<style lang="less" scoped>
.preferred-seller-icon {
  vertical-align: bottom;
  display: inline-flex;
  img{
    width: 100%;
  }
}
</style>
