// 计算当前商品行是否可以显示到手价倒计时(数据层面)的逻辑
// 因埋点上报逻辑也需要调用，故提取为工具函数

// 是否展示到手价信息
function isShowEstimated(item) {
  const {
    is_checked,
    per_coupon_discount,
    coupon_flag, // 自动用券
    is_auto_use_points_flag, // 自动用积分
  } = item
  // 未勾选 到手价展示逻辑
  if (is_checked === 0) return !!per_coupon_discount?.length
  // 勾选 到手价展示逻辑
  return coupon_flag == 1 || is_auto_use_points_flag == 1
}

// 当前商品行的促销活动中，结束时间最早的时间戳
function nextPromotionEndTime(item, integrityPromotionInfo) {
  // 兼容加价购主品特殊情况（及类似活动），取promotion_discount中的promotion_ids，为使得当前商品降价的有效活动id
  const includePromotionIds = item?.promotion_discount?.flatMap(p => p.promotion_ids ?? []) || []
  if (!integrityPromotionInfo || !includePromotionIds?.length) return null
  
  let minTimeStamp = Infinity
  includePromotionIds?.forEach(pid => {
    const curTimestamp = integrityPromotionInfo[pid]?.end_time
    minTimeStamp = Math.min(minTimeStamp, curTimestamp)
  })
  return minTimeStamp
}

/**
 * 当前商品行生效的优惠券中，失效时间最早的时间戳
 * 已勾选商品的优惠券信息是参数是couponInfos，取自cart/index接口中info下新增字段coupon_infos（后端定的）
 * 未勾选商品的优惠券信息是取商品行的per_coupon_discount
 * @param {*} cartItem 
 * @param {*} cartCouponInfos 
 * @returns 
 */
function nextCouponEndTime(cartItem, cartCouponInfos) {
  const isChecked = cartItem.is_checked
  if (isChecked) {
    if (cartCouponInfos && cartCouponInfos.length) {
      return cartCouponInfos.reduce(
        (min, obj) => obj?.use_end_time < min ? obj?.use_end_time : min,
        cartCouponInfos[0]?.use_end_time
      )
    }
    return null
  } else {
    const _couponInfos = cartItem?.per_coupon_discount
    if (_couponInfos && _couponInfos.length) {
      return _couponInfos.reduce(
        (min, obj) => obj?.end_time < min ? obj?.end_time : min,
        _couponInfos[0]?.end_time
      )
    }
    return null
  }
}

// 显示的倒计时时间戳，取最小的
function getTimeStamp(item, cartCouponInfos, integrityPromotionInfo, type = 'ESTIMATED') {
  let minTimeStamp
  const promotionEndTime = nextPromotionEndTime(item, integrityPromotionInfo)
  const couponEndTime = type === 'DISCOUNT' ? null : nextCouponEndTime(item, cartCouponInfos)
  if (promotionEndTime && couponEndTime) {
    minTimeStamp = Math.min(promotionEndTime, couponEndTime)
  } else {
    minTimeStamp = promotionEndTime ? promotionEndTime : couponEndTime
  }
  const now = Math.floor(Date.now() / 1000)
  return minTimeStamp > now ? minTimeStamp : null
}

// 距离优惠券或促销活动的失效/结束时间是否>24h
function isOver24h(item, cartCouponInfos, integrityPromotionInfo, type = 'ESTIMATED') {
  const timeStamp = getTimeStamp(item, cartCouponInfos, integrityPromotionInfo, type)
  if (!timeStamp) return true // 兼容结束时间为null
  return Math.floor(timeStamp - Date.now() / 1000) > 60 * 60 * 24
}

// 是否有其他倒计时(判断逻辑是从闪购倒计时的显示逻辑copy过来的)
function hasOtherCountdown(item, integrityPromotionInfo) {
  if (!integrityPromotionInfo) return false
  if (item.promotionTag?.typeId === 4 && [8, 14].includes(item.promotionTag.promotionLogoType)) { // 付费会员赠品
    return true
  }
  if (item.promotionTypeIds?.includes(3) || item.promotionTypeIds?.includes(8) || (item.promotionTypeIds?.includes(10) && [1, 4, 5, 6].includes(Number(item.flashType)))) {
    let promotion = item.product.product_promotion_info.find(i => [3, 8, 10].includes(i.type_id))
    if (promotion) {
      let targetPromotion = integrityPromotionInfo[promotion.promotion_id]
      if (targetPromotion?.is_count_down) {
        return true
      }
    }
  }
  if (item.product.insurable == 1 && item.product.insurable_time) {
    let end = item.product.insurable_time || 0
    if (end) {
      end = (end + '').length >= 13 ? end : end * 1000
    }
    const now = (new Date()).getTime()
    const diffTime = end - now
    const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24)
    return diffDays < 3 ? true : false
  }
  return false
}

/**
 * 计算当前商品行是否可以显示到手价倒计时(数据层面)
 * 1.当前该商品所参与的指定范围内的促销活动或优惠券中，其中最短的【距离活动或券的结束时间】<24小时
 * 2.当前该商品行上，没有其他倒计时
 * 3.商品行展示了到手价标签
 * @param { Object } item - 商品行信息
 * @param { Array } cartCouponInfos - 整车优惠券信息
 * @param { Object } integrityPromotionInfo - 活动信息
 */
function canShowCountdown(item, cartCouponInfos, integrityPromotionInfo, type = 'ESTIMATED') {
  if (type === 'DISCOUNT') {
    return !hasOtherCountdown(item, integrityPromotionInfo) &&
      !isOver24h(item, cartCouponInfos, integrityPromotionInfo, type)
  }
  return isShowEstimated(item) &&
    !hasOtherCountdown(item, integrityPromotionInfo) &&
    !isOver24h(item, cartCouponInfos, integrityPromotionInfo)
}

export {
  isShowEstimated,
  getTimeStamp,
  canShowCountdown
}
