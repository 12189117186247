<template>
  <template v-if="show">
    <div
      class="estimated-detail-cell estimated-detail-cell__flex pointer"
      @click="onClick"
    >
      <div class="estimated-detail-cell__title">
        <span>{{ title }}:</span>
      </div>
      <div class="estimated-detail-cell__value bold red">
        -{{ singleTotalPromotionDiscount.amountWithSymbol }}
        <Icon
          name="sui_icon_more_down_12px_1"
          size="12px"
          :class="[
            'estimated-detail__icon-more',
            isOpen && 'active',
          ]"
        />
      </div>
    </div>
    <div
      v-show="isOpen"
      class="estimated-detail__detail"
    >
      <div
        v-for="item in promotionDiscount"
        :key="item.promotion_id"
        class="estimated-detail__item"
      >
        <div class="estimated-detail__promotion-item">
          <div class="promotion-info">
            <span
              class="promotion-name"
              v-html="item.promotionName"
            ></span>
            <ClientOnly>
              <count-down-time
                v-if="showDetailPriceCountDown && fastestEndPromotion?.id === item?.promotion_id && fastestEndPromotion?.endIn24h"
                class="count-down"
                font-size="10px"
                not-hide
                not-reload
                :time-stamp="fastestEndPromotion?.endTime"
                :show-type="'inline-promotion'"
              />
            </ClientOnly>
          </div>
          <div class="saved">
            <span class="line">｜</span>
            <span>{{ template(item.single_discount_value.amountWithSymbol, language.SHEIN_KEY_PC_23749) }}</span>
          </div>
        </div>
        <div
          v-if="item.promotionTip"
          class="tip"
          v-html="item.promotionTip"
        ></div>
      </div>
      <div
        v-if="showSingleOtherDiscount"
        class="estimated-detail__item estimated-detail__promotion-item"
      >
        <span>{{ language.SHEIN_KEY_PC_17702 }}</span>
        <span class="line">｜</span>
        <span>{{ template(singleOtherDiscount.amountWithSymbol, language.SHEIN_KEY_PC_23749) }}</span>
      </div>
    </div>
  </template>
</template>

<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'
import CountDownTime from 'public/src/pages/cart_new/components/CartCountDown.vue'

export default {
  components: {
    Icon,
    ClientOnly,
    CountDownTime,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    language: {
      type: Object,
      required: true,
    },
    isSuggested: {
      type: [String, Boolean],
      default: false
    },
    cartItem: {
      type: Object,
      required: true,
    },
    integrityPromotionInfo: {
      type: Object,
      required: true,
    },
    flashSaleGroupList: {
      type: Object,
      required: true,
    },
    showDetailPriceCountDown: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    show() {
      return !!+this.singleTotalPromotionDiscount.amount
    },
    isSuggestedSpecialDe() {
      return this.isSuggested === 'special_de'
    },
    title() {
      switch (this.type) {
        case 'promotionA':
          return this.isSuggestedSpecialDe ? this.language.SHEIN_KEY_PC_31105 : this.language.SHEIN_KEY_PC_30550
        case 'promotionB':
          return this.isSuggestedSpecialDe ? this.language.SHEIN_KEY_PC_31106 : this.language.SHEIN_KEY_PC_30552
        default:
          return this.language.SHEIN_KEY_PC_29823
      }
    },
    singleTotalPromotionDiscount() {
      switch (this.type) {
        case 'promotionA':
          return this.cartItem?.single_every_body_promotion_discount || {} // everybodyprice 之上的优惠金额
        case 'promotionB':
          return this.cartItem?.single_exclusive_promotion_discount || {} // everybodyprice 之下的优惠金额
        default:
          return this.cartItem?.single_total_promotion_discount_without_prime || {}
      }
    },
    promotionDiscount() {
      let source = this.cartItem?.promotion_discount || []
      if (this.type) {
        source = source.filter(f => {
          if (this.type === 'promotionA') return f.every_body_price_type === 1
          if (this.type === 'promotionB') return f.every_body_price_type === 2
          return true
        })
      }

      if (!source.length) return []
      const showDetailTipTids = [1, 9, 14, 15, 16, 17, 18, 19, 25, 26]
      const showTitleTipTids = [2, 3, 4, 8, 10, 11, 12, 13, 22, 24, 28, 30, 33]
      const titleLangMap = {
        '2': this.language.SHEIN_KEY_PC_15751,
        '3': this.language.SHEIN_KEY_PC_17697,
        '4-0': this.language.SHEIN_KEY_PC_15751,
        '4-8': this.language.SHEIN_KEY_PC_30894,
        '8': this.language.SHEIN_KEY_PC_17698,
        '10-1': this.language.SHEIN_KEY_PC_15950, // 常规闪购
        '10-2': this.language.SHEIN_KEY_PC_23050, // 新人闪购
        '10-3': this.language.SHEIN_KEY_PC_23052, // 特殊闪购
        '10-4': this.language.SHEIN_KEY_PC_27297, // 分人群专区闪购
        '10-5': this.language.SHEIN_KEY_PC_27297, // 不分人群专区闪购
        '10-6': this.language.SHEIN_KEY_PC_29870, // 品牌特卖
        '11': this.language.SHEIN_KEY_PC_15950,
        '12': this.language.SHEIN_KEY_PC_17699,
        '13': this.language.SHEIN_KEY_PC_15966,
        '22': this.language.SHEIN_KEY_PC_15966,
        '24': this.language.SHEIN_KEY_PC_17701,
        '28': this.language.SHEIN_KEY_PC_15751,
        // '29': this.language.SHEIN_KEY_PC_22977 + ':',
        '30': this.underPriceAbtValue ? this.language.SHEIN_KEY_PC_24789 : this.language.SHEIN_KEY_PC_24790
      }

      const res = []
      source.forEach(item => {
        const id = item.promotion_id
        const tid = +item.promotion_type

        if (tid == 31) {
          if(item.promotion_logo_type == 3) {
            res.push({
              ...item,
              promotionName: this.language.SHEIN_KEY_PC_27730,
            })
          } else if(item.promotion_logo_type != 1 && item.promotion_logo_type != 3) {
            res.push({
              ...item,
              promotionName: this.language.SHEIN_KEY_PC_28264,
            })
          }
        }

        // 保价的活动id，活动类型，活动标识均为null
        if (item.promotion_id === null && item.promotion_type === null && item.promotion_logo_type === null) {
          res.push({
            ...item,
            promotionName: this.language.SHEIN_KEY_PC_23163,
          })
        }

        const isShowDetailTip = showDetailTipTids.includes(tid)
        const isShowTitleTip = showTitleTipTids.includes(tid)
        if (!isShowDetailTip && !isShowTitleTip) return

        if (showDetailTipTids.includes(tid)) {
          let promotionInfoContent = this.integrityPromotionInfo[id]?.content || {}
          if (promotionInfoContent.tips) {
            const tipsIndex = promotionInfoContent.tipsIndex
            const tipsList = promotionInfoContent.tips.list
            res.push({
              ...item,
              promotionName: tipsList[tipsIndex] || ''
            })
          }
        } else if (showTitleTipTids.includes(tid)) {
          // 闪购活动
          if (tid === 10) {
            let promotionName = ''
            let promotionTip = ''
            const { flash_type } = this.integrityPromotionInfo[id]
            if ([4, 5].includes(flash_type) && this.cartItem?.promotionTag?.typeIdText && this.cartItem?.promotionTag?.promotionId === id) {
              // 要求与商品行展示的活动名称一致
              promotionName = this.cartItem?.promotionTag?.typeIdText
            } else {
              promotionName = titleLangMap[`${tid}-${flash_type}`]
            }
            // 超限购买提示
            if(this.flashSaleGroupList[id] && this.integrityPromotionInfo[id]?.limit_mode == 1){ // 闪购活动且支持超限原价购买,库存超限购买提示
              if (this.cartItem?.is_checked == 1) {
                const promo = this.cartItem?.product?.product_promotion_info?.find(f => f.promotion_id === id)
                const falshLimit = promo && promo.single_over == 1 || promo.member_over == 1
                if (falshLimit) {
                  promotionTip = this.language.SHEIN_KEY_PC_24348
                }
              }
            }
            res.push({
              ...item,
              promotionName,
              promotionTip,
            })
          } else if(tid === 4) { // 全场满赠（全场满赠、付费会员权益礼物）
            const { promotion_logo_type } = this.integrityPromotionInfo[id]
            const promotionName = titleLangMap[`${tid}-${promotion_logo_type}`] || titleLangMap[`${tid}-0`]
            res.push({
              ...item,
              promotionName
            })
          } else if (tid == 33) {
            let catNameMulti = this.integrityPromotionInfo[id]?.category_info?.[0]?.cat_name_multi || ''
            let promotionName = template(catNameMulti, this.language.SHEIN_KEY_PC_30876)
            res.push({
              ...item,
              promotionName,
              promotionTip: this.language.SHEIN_KEY_PC_30875
            })
          } else {
            res.push({
              ...item,
              promotionName: titleLangMap[tid]
            })
          }
          // 库存超限提示
          if (this.cartItem?.is_checked == 1 && this.cartItem?.limit_mode) {
            const promo = res.pop()
            promo.promotionTip = promo.promotionTip || this.language.SHEIN_KEY_PWA_23038
            res.push(promo)
          }
        }
      })

      return res
    },
    showSingleOtherDiscount() {
      // B 类活动不展示
      if (this.type === 'promotionB') return false
      return this.singleOtherDiscount.amount > 0
    },
    singleOtherDiscount() {
      return this.cartItem?.single_other_discount || {}
    },

    // 所有活动中，最快结束的活动信息
    fastestEndPromotion() {
      const includePromotionIds = this.cartItem?.promotion_discount
        ?.map(p => p.promotion_id)
        ?.filter(id => id !== null && id !== undefined) || []
      if (!this.integrityPromotionInfo || !includePromotionIds?.length) return null
      
      let minTimeStamp = Infinity
      let id = null
      includePromotionIds?.forEach(pid => {
        const curTimestamp = this.integrityPromotionInfo[pid]?.end_time
        if (curTimestamp && curTimestamp < minTimeStamp) {
          minTimeStamp = curTimestamp
          id = pid
        }
      })
      
      const endIn24h = minTimeStamp !== Infinity
        ? (Math.floor(minTimeStamp - Date.now() / 1000) < 60 * 60 * 24)
        : false

      return {
        id,
        endIn24h,
        endTime: minTimeStamp !== Infinity ? minTimeStamp : null,
      }
    },
  },
  methods: {
    template,
    onClick() {
      this.isOpen = !this.isOpen
    },
    reset() {
      this.isOpen = false
    },
  },
}
</script>

<style lang="less" scoped>
.estimated-detail {
  &__item {
    padding: 8px;
    font-size: 12px;
    color: #767676;
    background: #F6F6F6;
    &:first-child {
      box-shadow: 0 .5px 0 0 #E5E5E5 inset;
    }
    .line {
      color: #ccc;
    }
    > * {
      flex-shrink: 0;
    }
    .tip {
      margin-top: 4px;
      font-size: 10px;
      color: rgba(#000, .3);
    }
  }

  &__promotion-item {
    max-height: 34px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    .promotion-info {
      max-width: 100%;
      display: inline-flex;
      align-items: center;
    }
    .promotion-name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .count-down {
      overflow: hidden;
      white-space: nowrap;
      width: 60px;
      display: inline-block;
      margin-left: 4px;
      line-height: 0;
      margin-top: 0;
      :deep(.time-content) { /* stylelint-disable-line */
        color: rgba(250, 99, 56, 1)!important; /* stylelint-disable-line declaration-no-important */
      }
      :deep(.count-number) { /* stylelint-disable-line */
        height: 14px;
        line-height: 17px;
        background: rgba(255, 236, 233, 1);
        border-radius: 2px;
        color: rgba(250, 99, 56, 1)!important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .saved {
      margin: 4px 0;
      display: inline;
      line-height: 17px;
    }
  }

  &__icon-more {
    position: absolute;
    right: -12px;
    color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */

    &.active {
      transform: rotateX(180deg);
    }
  }
}
</style>
