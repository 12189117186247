<template>
  <template v-if="show">
    <div
      class="estimated-detail-cell pointer"
      @click="onClick"
    >
      <div class="estimated-detail-cell__flex">
        <div class="estimated-detail-cell__title">
          <span>{{ language.SHEIN_KEY_PC_29825 }}:</span>
        </div>
        <div class="estimated-detail-cell__value  bold red">
          -{{ singleCouponDiscount.amountWithSymbol }}
          <Icon
            name="sui_icon_more_down_12px_1"
            size="12px"
            :class="[
              'estimated-detail__icon-more',
              isOpen && 'active',
            ]"
          />
        </div>
      </div>
      <div class="estimated-detail-cell__tip">
        {{ isSuggested ? language.SHEIN_KEY_PC_30555 : language.SHEIN_KEY_PC_25974 }}
      </div>
    </div>
    <div
      v-show="isOpen"
      class="estimated-detail__detail"
    >
      <div
        v-for="item in perCouponDiscount"
        :key="item.coupon_code"
        class="estimated-detail__item"
      >
        <div class="estimated-detail__coupon-item">
          <div class="coupon-info">
            <EasyCoupon
              class="coupon-code"
              :lang-text="language.SHEIN_KEY_PC_25973"
              :show-lang-text="item.showLangText"
              :coupon-code="item.coupon_code"
            />
            <ClientOnly>
              <count-down-time
                v-if="showDetailPriceCountDown && fastestEndCoupon?.couponCode === item?.coupon_code && fastestEndCoupon?.endIn24h"
                class="count-down"
                font-size="10px"
                not-hide
                not-reload
                :time-stamp="fastestEndCoupon?.endTime"
                :show-type="'inline-promotion'"
              />
            </ClientOnly>
          </div>
          <span class="saved">{{ template(item.single_discount_value.amountWithSymbol, language.SHEIN_KEY_PC_23749) }}</span>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'

import EasyCoupon from '../getCoupon/EasyCoupon.vue'
import CountDownTime from 'public/src/pages/cart_new/components/CartCountDown.vue'

export default {
  components: {
    Icon,
    EasyCoupon,
    ClientOnly,
    CountDownTime,
  },
  props: {
    language: {
      type: Object,
      required: true,
    },
    cartItem: {
      type: Object,
      required: true,
    },
    isSuggested: {
      type: [String, Boolean],
      default: false
    },
    cartCouponInfos: {
      type: Array,
      default: () => []
    },
    showDetailPriceCountDown: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    show() {
      return !!+this.singleCouponDiscount.amount
    },
    singleCouponDiscount() {
      return this.cartItem?.single_coupon_discount || {}
    },
    perCouponDiscount() {
      let res = this.cartItem?.per_coupon_discount || []
      res = res.map(item => ({
        ...item,
        showLangText: true,
      }))

      const specialCoupon = this.cartItem?.promotion_discount?.find?.(p => p.promotion_type == 32)
      if (specialCoupon) {
        // 将活动作为优惠券传入并按价格从高到低排序
        const index = res.findLastIndex(item => item.single_discount_value?.amount >= specialCoupon.single_discount_value?.amount)
        res.splice(index + 1, 0, {
          ...specialCoupon,
          showLangText: false,
          coupon_code: this.language.SHEIN_KEY_PC_27371,
        })
      }
      return res
    },

    /**
     * 当前商品行生效的优惠券中，最快结束的优惠券信息
     * 已勾选商品的优惠券信息是参数是couponInfos，取自cart/index接口中info下新增字段coupon_infos（后端定的）
     * 未勾选商品的优惠券信息是取商品行的per_coupon_discount
     */
    fastestEndCoupon() {
      // 获取结束时间最早的优惠券
      const getEarliestEndingCoupon = (coupons) => {
        return coupons?.reduce(
          (couponA, couponB) => !couponA || (couponB?.end_time < couponA?.end_time) ? couponB : couponA,
          null
        ) || {}
      }

      const isChecked = this.cartItem?.is_checked
      const targetCoupon = isChecked
        ? getEarliestEndingCoupon(this.cartCouponInfos?.map(coupon => ({
          ...coupon,
          idKey: 'use_end_time',
          coupon_code: coupon?.coupon_code,
          end_time: coupon?.use_end_time,
        })))
        : getEarliestEndingCoupon(this.cartItem?.per_coupon_discount?.map(coupon => ({
          ...coupon,
          idKey: 'end_time',
          coupon_code: coupon?.coupon_code,
          end_time: coupon?.end_time,
        })))

      const endTime = targetCoupon?.end_time
      const endIn24h = endTime ? (Math.floor(endTime - Date.now() / 1000) < 60 * 60 * 24) : false

      return {
        couponCode: targetCoupon?.coupon_code || null,
        endTime: endTime || null,
        endIn24h: endIn24h,
      }
    },
  },
  methods: {
    template,
    onClick() {
      this.isOpen = !this.isOpen
    },
    reset() {
      this.isOpen = false
    },
  },
}
</script>

<style lang="less" scoped>
.estimated-detail {
  &__item {
    padding: 5px 8px;
    color: #767676;
    background: #F6F6F6;
    font-size: 12px;
    &:first-child {
      box-shadow: 0 .5px 0 0 #E5E5E5 inset;
    }
    
  }

  &__coupon-item {
    max-height: 38px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    .coupon-info {
      max-width: 100%;
      display: inline-flex;
      align-items: center;
    }
    .coupon-code {
      flex: 1;
      box-sizing: border-box;
      height: 18px!important; /* stylelint-disable-line declaration-no-important */
    }
    .count-down {
      overflow: hidden;
      white-space: nowrap;
      width: 60px;
      display: inline-block;
      margin-left: 4px;
      line-height: 0;
      margin-top: 0;
      :deep(.time-content) { /* stylelint-disable-line */
        color: rgba(250, 99, 56, 1)!important; /* stylelint-disable-line declaration-no-important */
        font-weight: 400!important; /* stylelint-disable-line declaration-no-important */
      }
      :deep(.count-number) { /* stylelint-disable-line */
        height: 14px;
        line-height: 18px;
        background: rgba(255, 236, 233, 1);
        border-radius: 2px;
        color: rgba(250, 99, 56, 1)!important; /* stylelint-disable-line declaration-no-important */
        font-weight: 400!important; /* stylelint-disable-line declaration-no-important */
      }
    }
    .saved {
      margin-left: 4px;
      display: inline;
      line-height: 20px;
    }
  }

  &__icon-more {
    position: absolute;
    right: -12px;
    color: @sui_color_gray_light1 !important; /* stylelint-disable-line declaration-no-important */

    &.active {
      transform: rotateX(180deg);
    }
  }
}
</style>
